import React, { memo, useMemo, useState } from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { RxCrossCircled } from "react-icons/rx";
import { GrInProgress } from "react-icons/gr";
import { apiGenerator, PERMISSION } from "../../../util/functions";
import { CheckCircleOutlined } from "@ant-design/icons";
import useHttp from "../../../hooks/use-http";
import CRUDComponent from "../../../component/common/CRUD-Component";
import { useNavigate, useParams } from "react-router-dom";
import { SlCheck, SlClose } from "react-icons/sl";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import AnalyzeModal from "./AnalyzeModal";
import { Button, Popconfirm, Row } from "antd";

const RequestList = ({ accessPermission, setRefreshAmount = () => {} }) => {
  const API = useHttp();
  const { id } = useParams();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [analyzeData, setAnalyzeData] = useState(false);
  const [openResonModal, setOpenResonModal] = useState(null);

  const requestHandler = (data) => {
    const { status, dataId } = data;

    const payload = {
      status,
    };
    const REQUEST_API = apiGenerator(
      CONSTANTS.API?.Role_Management?.adminApproval,
      {
        dataId,
      }
    );
    API.sendRequest(
      REQUEST_API,
      () => {
        setRefresh((prev) => !prev);
        setRefreshAmount((pre) => !pre);
      },
      payload
    );
  };

  const OnFormSubmitBtn = (res) => {
    const payload = {
      isBlocked: !!openResonModal,
      blockReason: res?.blockReason ?? "",
    };
    const UpdateAPIEnd = {
      ...CONSTANTS?.API.user.update,
    };
    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${openResonModal ?? res}`;
    API.sendRequest(
      UpdateAPIEnd,
      () => {
        setOpenResonModal(null);
        setRefresh((prev) => !prev);
      },
      payload,
      `User ${payload?.isBlocked ? "Blocked" : "Unblock"} Successfully`
    );
  };

  const getReferredUsers = (data, taskCount) => {
    API.sendRequest(
      CONSTANTS.API.user.getAll,
      (res) => {
        if (res?.data?.rows)
          analyzeUser(data?.user, data?.amount, res?.data?.rows, taskCount);
      },
      {
        referenceCode: data?.user?.myReferralCode,
        page: 1,
        limit: 100000,
      }
    );
  };

  const analyzeUser = async (user, amount, referredUsers, taskCount) => {
    let report = {
      userId: user.id,
      userName: user.name,
      isBlocked: user.isBlocked,
      amount: amount,
      duplicateDeviceCount: 0,
      nullDeviceCount: 0,
      blockedUsersCount: 0,
      platformUsage: {},
      deviceUsage: {},
      blockedReason: {},
      totalReferredUsers: referredUsers?.length,
      frequentLoginsCount: 0, // This can be based on your frequency threshold logic
      taskCount: taskCount || 0,
      isPassed: false,
      "Remaining Block User Count Above 40%": 0,
    };

    const deviceIds = new Set();

    referredUsers?.length &&
      referredUsers.forEach((referredUser) => {
        // Check duplicate device IDs
        if (referredUser.deviceId == null) {
          report.nullDeviceCount++;
        } else if (deviceIds.has(referredUser.deviceId)) {
          report.duplicateDeviceCount++;
        } else {
          deviceIds.add(referredUser.deviceId);
        }

        if (referredUser.isBlocked) {
          report.blockedReason[referredUser.blockReason] =
            (report.blockedReason[referredUser.blockReason] || 0) + 1;
        }

        report.deviceUsage[referredUser.deviceId] =
          (report.deviceUsage[referredUser.deviceId] || 0) + 1;

        // Check platform usage
        if (report.platformUsage[referredUser.deviceCategory]) {
          report.platformUsage[referredUser.deviceCategory]++;
        } else {
          report.platformUsage[referredUser.deviceCategory] = 1;
        }

        // Count blocked users
        if (referredUser.isBlocked) {
          report.blockedUsersCount++;
        }
      });

    //   console.log("report", user.id, "task", taskCount?.[user.id], report);
    const userLeftPr = +(
      (report.nullDeviceCount / report.totalReferredUsers) *
      100
    ).toFixed(0);

    const userBlockPr = +(
      (report.blockedUsersCount / report.totalReferredUsers) *
      100
    ).toFixed(0);

    // If there are no duplicates, null devices, or blocked users, and the user has completed at least one task, accept the payment
    report["Remaining Block User Count Above 40%"] =
      report.blockedUsersCount -
      +((report.totalReferredUsers * 40) / 100).toFixed(0);

    if (
      report.duplicateDeviceCount < 2 &&
      (report.totalReferredUsers == 0 || userBlockPr < 40) &&
      (report.totalReferredUsers == 0 ||
        report.nullDeviceCount < 5 ||
        userLeftPr < 40)
    ) {
      report.isPassed = true;
    } else if (report.taskCount >= 1) {
      report.isPassed = false;
    }
    setAnalyzeData(report);
    // return report;
  };

  const memoizedExtraQuery = useMemo(() => {
    return {
      ...(id && { userId: id }),
      status: "PendingFromAdminSide",
    };
  }, [id]);


  return (
    <>
      <Row className="flex justify-end">
        <Popconfirm onConfirm={() => {}} title="Are you sure?">
          <Button className="float-right me-4" type="primary">
            Approve All
          </Button>
        </Popconfirm>
      </Row>
      <CRUDComponent
        title="User"
        reload={refresh}
        accessPermission={accessPermission}
        GET={{
          API: CONSTANTS.API.trajection.getAll,
          extraQuery: memoizedExtraQuery,
          DataModifier: (res, API, Setrefresh, extraData) => {
            const taskCount = extraData?.taskCounts?.reduce((acc, obj) => {
              acc[obj.userId] = obj.totalTaskCount;
              return acc;
            }, {});
            return res?.map((data) => {
              return {
                ...data,
                no: data?.id,
                view: {
                  id: data?.user?.id,
                  onViewMore: (id) => {
                    navigate(`/app/user/${id}`);
                  },
                  onAnalyzeUser: () =>
                    getReferredUsers(data, taskCount[data?.userId] ?? 0),
                },
                name: data?.user?.name,
                image: data?.user?.image,
                userImage: { image: data?.user?.image, name: data?.user?.name },
                isRootDevice: (
                  <>
                    {data?.user?.isRootDevice ? (
                      <SlCheck className="text-2xl" color="green" />
                    ) : (
                      <SlClose className="text-2xl" color="red" />
                    )}
                  </>
                ),
                referralCount: data?.user?.referralCount,
                sameDeviceIdCount: data?.user?.sameDeviceIdCount,
                deviceId: data?.user?.deviceId,
                mobile: data?.user?.mobile,
                score: data?.user?.score,
                totalTask: taskCount[data?.userId] ?? 0,
                isBlocked: {
                  checked: !data?.user?.isBlocked,
                  id: data?.user?.id,
                  isDisable: PERMISSION[accessPermission] === "READ",
                  onClick: (id, val) => {
                    val ? setOpenResonModal(id) : OnFormSubmitBtn(id);
                  },
                },
                request: {
                  id: data?.id,
                  onAccept: () => {
                    const load = {
                      dataId: data?.id,
                      status: "Success",
                    };
                    requestHandler(load);
                  },
                  onReject: () => {
                    const load = {
                      dataId: data?.id,
                      status: "Fail",
                    };
                    requestHandler(load);
                  },
                },
                // identify: (
                //   <>
                //     {data?.user?.isGovermentIdVerified === "Verified" ? (
                //       <CheckCircleOutlined className="text-2xl text-[#007BFF]" />
                //     ) : data?.user?.isGovermentIdVerified === "Failed" ? (
                //       <RxCrossCircled className="text-2xl text-red-500" />
                //     ) : (
                //       <GrInProgress className="text-2xl text-slate-500" />
                //     )}
                //   </>
                // ),
              };
            });
          },
          column: CONSTANTS.TABLE.WITHDRAWAL_REQUEST,
        }}
        isSearch
      />
      <ModalFormCreator
        loading={API.isLoading}
        open={openResonModal}
        onCreate={OnFormSubmitBtn}
        onCancel={() => {
          setOpenResonModal(null);
        }}
        menuFields={CONSTANTS.FORM_FIELD.USER_BLOCK_FORM}
        name={"Reason"}
        SubmitName={"Submit"}
      />
      <AnalyzeModal analyzeData={analyzeData} setAnalyzeData={setAnalyzeData} />
    </>
  );
};

export default memo(RequestList);
